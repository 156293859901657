
/*
*
*
*
*
*
*/
import { createStore } from 'vuex'
import * as redeemer from '../redeemer'
import * as forge from '../forge'

export default createStore({
    state: {

        'CONSTANTS': forge.CONSTANTS,
        'USER_LOGGEDIN': false,
        'USER_COMPANY_VIEW': '',    // current company slug
        'USER_STATE': '',           // 1-owner,2-client,3-personnel
        'USER_PRIVILEGE': {},       // role privileges

        'show_debug': forge.show_debug,

        'show_datetime_output': forge.show_datetime_output,
        'show_datetime': forge.show_datetime,
        'show_date': forge.show_date,

        'change_title_tag': forge.change_title_tag,
        'GET_RANDOMSTRING': forge.GET_RANDOMSTRING,

        'is_empty': forge.IS_EMPTY,
        'RESIZE_PHOTO': forge.RESIZE_PHOTO,
        'str_json': forge.str_json,

        'get_geopattern': forge.get_geopattern,
        'get_pattern_url': forge.get_pattern_url,
        'CHANGE_BACKGROUND_IMAGE': forge.CHANGE_BACKGROUND_IMAGE,

        'set_darkmode': forge.set_darkmode,
        'set_lightmode': forge.set_lightmode,

        'set_cookie': forge.set_cookie,
        'get_cookie': forge.get_cookie,

        //'get_tinymce_api': forge.get_tinymce_api,

        'redeem_api_no_key': redeemer.redeem_api_no_key,
        'redeem_api': redeemer.redeem_api,
        'redeem_upload_img': redeemer.redeem_upload_img,

    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
