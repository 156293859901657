<template>
    <div class="page-wrapper compact-wrapper" id="id_app">
        
        <router-view />

    </div>
</template>
<script>

module.exports = {

    created(){

        // //
        // // do not implement light mode / dark mode on external pages
        // // this has now changed, forms need darkmode function
        //
        // if(this.$store.state.get_cookie('darkmode') == 1){
        //     document.body.classList.add("dark-only")
        // }
        // else {
        //     document.body.classList.remove("dark-only")
        // }
    }

}

</script>
<style>
</style>