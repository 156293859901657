import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/404/',
        name: 'empty_home',
        component: () => import('../views/404.vue')
    },
    {
        path: '/500/',
        name: 'empty_home',
        component: () => import('../views/500.vue')
    },

    {
        path: '/',
        name: 'empty_home',
        component: () => import('../views/empty_home.vue')
    },
    {
        path: '/exlp/:slug/:pslug/',
        name: 'shared_product',
        component: () => import('../views/shared_products.vue')
    },
    {
        path: '/forms/:slug/:fslug/',
        name: 'forms_page',
        component: () => import('../views/forms.vue')
    },
    {
        path: '/form-preview/:fslug/',
        name: 'form_preview_page',
        component: () => import('../views/form_preview.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
