
/*
*
*
*
*
*/
const _internal_version = '/internalv1'
const axios = require('axios');
import { CONSTANTS, show_debug } from './forge';

/*
*
*
*
*
*/
const url_builder = function(u){

    /*
    *
    *
    *
    *
    *
    */
    let _url = ''
    _url = CONSTANTS['DOMAIN_API'] + _internal_version + u + '?format=json'
    show_debug(_url)
    return _url
}

/*
*
*
*
*
*/
export const redeem_api_no_key = async function(kwargs){

    /*
    *
    *
    *
    *
    *
    */
    let _url = url_builder(kwargs['url'])
    show_debug(_url)

    /*
    *
    *
    *
    *
    *
    */
    /*
    * used for get url param
    *
    kwargs['param'] = [
            {
                'key':'your_key',
                'value': 'your_value',
            },
        ]
    */
    if('param' in kwargs) {

        for (let i = 0; i < kwargs['param'].length; i++) {
            let _k = kwargs['param'][i]['key']
            let _v = kwargs['param'][i]['value']
            _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
        }

    }

    /*
    *
    * data
    *
    *
    *
    */
    if(kwargs['type'] == 'GET') {
        
        //
        //
        return await axios.get(_url)
    }
    else if(kwargs['type'] == 'POST') {

        //
        //
        /*
        POST will require data/kwargs['data]
            {
                key1: value1,
                key2: value2,
            }
        */
        return await axios.post(_url,kwargs['data'])
    }

}

/*
*
*
*
*
*/
export const redeem_api = async function(kwargs){

    /*
    * --------- HOW TO USE ---------------
    * 
    * kwargs = {
    *   'domain': 'http://localhost:8081/',
    *   'key': this.key,
    *   'type': 'POST',
    *   'url': '/api/query/',
    *   'data': {
    *       key: value,
    *   },
    *   'debug': true,
    * }
    * 
    * kwargs = {
    *   'domain': 'http://localhost:8081/',
    *   'key': this.key,
    *   'type': 'GET',
    *   'url': '/api/query/',
    *   'param': [
    *       {
    *           'key': 'value',
    *       },
    *   ],
    *   'debug': true,
    * }
    * 
    * --------------------------------------
    * 
    */

    /*
    *
    *
    *
    *
    *
    */
    let _domain = CONSTANTS['DOMAIN_API']
    let _type = kwargs['type']              // GET / POST
    let _key = ''

    /*
    *
    *
    *
    *
    *
    */
    if('key' in kwargs){
        _key = '&token=' + String(kwargs['key']) // api key
    }
    else {
        _key = ''
    }

    /*
    *
    *
    *
    *
    *
    */
    let _url = ''
    if('is_api' in kwargs){
        if(kwargs['is_api']==true) {
            _url = _domain + _internal_version + kwargs['url'] + '?format=json' + _key
        }
        else {
            _url = _domain + kwargs['url'] + '?format=json' + _key
        }
    }
    else {
        _url = _domain + _internal_version + kwargs['url'] + '?format=json' + _key    // '/v1/query/
    }
    show_debug(_url)

    /*
    *
    *
    *
    *
    *
    */
    /*
    * used for get url param
    *
    kwargs['param'] = [
            {
                'key':'your_key',
                'value': 'your_value',
            },
        ]
    */
    if('param' in kwargs) {

        for (let i = 0; i < kwargs['param'].length; i++) {
            let _k = kwargs['param'][i]['key']
            let _v = kwargs['param'][i]['value']
            _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
        }

    }

    /*
    *
    *
    *
    *
    *
    */
    // let _config = {
    //     headers: {
    //         //"Access-Control-Allow-Origin": "*",
    //     }
    // }

    /*
    *
    * data
    *
    *
    *
    */
    if(_type == 'GET') {
        
        //
        //
        return await axios.get(_url)
    }
    else if(_type == 'POST') {

        //
        //
        /*
        POST will require data
            {
                key1: value1,
                key2: value2,
            }
        */
        return await axios.post(_url,kwargs['data'])
    }

}

/*
*
*
*
*
*/
export const redeem_upload_img = async function(url,data){

    return await axios.post(url,data)

}





