
/*
*
*
*
*
*
*/
import moment from 'moment'
//import * as redeemer from './redeemer'

/*
*
*
*
*
*
*/
const DBG = false

//
//
const debug_var = function(dbg,var1,var2){
    if(dbg==true){ return var1 } else { return var2 }
}
//
//
export const CONSTANTS = {

    'DEBUG_MODE': DBG,
    'APP_NAME': 'Hierarch - Landing Pages',

    'DOMAIN': debug_var(
        DBG,
        'http://localhost:8081',
        'https://app.hierarch.io'
    ),
    'DOMAIN_API': debug_var(
        DBG,
        'http://127.0.0.1:8000',
        'https://api.hierarch.io'
    ),
    'DOMAIN_LP': debug_var(
        DBG,
        'http://localhost:8081/lp',
        'https://lp.hierarch.io'
    ),

    'Q_FORMAT': 'format=json',
    'TEXT_EDITOR_MODE': 'tinymce', // tinymce / textarea / summernote
    'TINYMCE_INIT':{
        menubar:false,                       
        toolbar_mode: 'wrap',
        toolbar: 'forecolor backcolor | h1 h2 h3 |  fontfamily fontsize | indent outdent | bold italic blockquote strikethrough numlist bullist | alignleft aligncenter alignright alignjustify alignnone | link image table | code',

        plugins: 'lists link image table code help wordcount',
        
        //
        //
        skin: 'oxide-dark',
        content_css: 'dark',

        //
        //
        typography_default_lang: 'en-US',
        typography_langs: [
            'en-US',
            'es',
            'de'
        ],

    },
    'PAYMENT_PROVIDER': 'lemonsqueezy',
}

//
//
export const show_debug = function(v){
    if(CONSTANTS['DEBUG_MODE']==true){
        console.log(v)
    }
}

//
//
export const REDIRECT_TO = function(complete_uri){
    //window.location.replace(DOMAIN + BASE_URL + p)
    location.href = complete_uri
}

//
//
export const change_title_tag = function(v){
    document.title = v + ' | ' + CONSTANTS['APP_NAME']
}

//
//
export const GET_RANDOMSTRING = function(length) {
    var result           = ''
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
   return result
}

//
//
export const show_datetime_output = function(dt,_format) {
    return moment(String(dt)).format(_format)
}

//
//
export const show_datetime = function(dt) {
    return moment(String(dt)).format('MMM DD, YYYY - hh:mm a')
}

//
//
export const show_date = function(dt) {
    return moment(String(dt)).format('MMM DD, YYYY')
}

//
//
export const IS_EMPTY = function(value){
    if(value == null || value == undefined){
        return true
    }
    else {
        return false
    }
}

//
//
export const RESIZE_PHOTO = function(photo,size){

    if(photo!=null||photo==''||photo=='#'){
        return photo.replace('image/upload/','image/upload/w_' + size + ',h_' + size + '/')
    }
    else {
        return photo
    }
}

//
// this is critical
export const str_json = function(str){
    return JSON.parse(str.replace(/'/g, '"'))
}

//
//
export const set_darkmode = function(){

    //
    //
    manage_state('d',1)
}

//
//
export const set_lightmode = function(){

    //
    //
    manage_state('d',0)
}

// ==================================================================
// localstorage, sessionstorage, cookies, etc.
// ==================================================================

/*
* set_cookie('session', value, 30) - to set
* set_cookie('session', value, -1) - to delete
*
*
*/

//
//
export const set_cookie = function(cname, cvalue, expiry_days) {
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//
//
export const get_cookie = function(cname){

    // returns value of cookie['']

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

//
//
export const if_cookie_exists = function(cname){

    // returns true or false

    let the_cookie = get_cookie(cname);
    if (the_cookie != "") {
        return true
    } else {
        return false
    }
}

//
// get value from localstorage
export const view_state = function(k){

    //
    //
    return window.localStorage.getItem(k)
}

//
// save value to localstorage
export const manage_state = function(k,v){

    //
    //
    window.localStorage.setItem(k, v)
    window.localStorage.getItem(k)
}

//
// check if value exists in localstorage
export const verify_state = function(k){

    //
    //
    show_debug('verify_state')

    //
    //
    if( view_state(k)==null || view_state(k)==0 ){
        return false
    }
    else{
        return true
    }
}

//
//
export const remove_state = function(v){
    window.localStorage.removeItem(v)
}

// ==================================================================

//
//
export const encode_va_json_cookie = function(v){

    //
    //
    let r = ''
    r = r.concat('id,,' + v['id'])
    r = r.concat('||name,,' + v['name'])
    r = r.concat('||photo,,' + v['photo'])

    //
    //
    return {
        'id': v['id'],
        'value': r,
    }

}

//
//
export const decode_va_json_cookie = function(v){

    //
    //
    let r = {}
    let x = v.split('||')

    //
    //
    for (let i = 0; i < x.length; i++) {

        //
        //
        let y = x[i].xplit(',,')

        //
        //
        r[ y[0] ] = y[1]
    }

    //
    //
    return r

}

// ==================================================================

// ==================================================================
// random geometric background image generation
// ==================================================================

//
//
var GeoPattern = require('geopattern');
export const get_geopattern = function(){
    return GeoPattern
}

//
//
export const get_pattern_url = function(text){
    let pattern = GeoPattern.generate(text).toDataUrl()
    //return pattern.toDataUrl()
    return pattern
}

//
//
export const CHANGE_BACKGROUND_IMAGE = function(the_id,text) {
    document.getElementById(the_id).style.backgroundImage = 'url(buttons/' + get_pattern_url(text) + '.png)';
}

//
//
export const stripHtml = function(html) {
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

// ==================================================================
// TinyMCE
// ==================================================================

//
//
// export const get_tinymce_api = function(key){

//     //
//     //
//     var r = ''
//     console.log('get_tinymce_api')
//     //
//     //
//     redeemer.redeem_api({
//         'key': key,
//         'type':'GET',
//         'url':'/session/tinymce/'
//     })
//     .then(response => {
//         show_debug(response)
//         r = response.data
//     })
//     .catch(error => {
//         show_debug(error)
//         r = false
//     })

//     //
//     //
//     console.log(r)
//     return r

// }

